import * as Types from '../../../graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

/*
* This was automatically generated on by @graphql-codegen.
* DO NOT MANUALLY EDIT !!!
* Please refer to https://github.com/wistia/wistia/blob/master/webpack/graphql/README.md for more information.
* Last Updated: Mon Oct 14 2024 17:52:47 GMT+0000 (Coordinated Universal Time)
*/

const defaultOptions = {} as const;
export type AnonymousFlagsmithIdentityAndTraitsLoaderQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AnonymousFlagsmithIdentityAndTraitsLoaderQuery = { __typename?: 'Query', currentAccount?: { __typename?: 'Account', id: string, flagsmithId: string, plan?: { __typename?: 'Plan', id: string, free: boolean } | null | undefined } | null | undefined };


export const AnonymousFlagsmithIdentityAndTraitsLoaderDocument = gql`
    query AnonymousFlagsmithIdentityAndTraitsLoader {
  currentAccount {
    id
    flagsmithId(isAnonymous: true)
    plan {
      id
      free
    }
  }
}
    `;

/**
 * __useAnonymousFlagsmithIdentityAndTraitsLoaderQuery__
 *
 * To run a query within a React component, call `useAnonymousFlagsmithIdentityAndTraitsLoaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnonymousFlagsmithIdentityAndTraitsLoaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnonymousFlagsmithIdentityAndTraitsLoaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnonymousFlagsmithIdentityAndTraitsLoaderQuery(baseOptions?: Apollo.QueryHookOptions<AnonymousFlagsmithIdentityAndTraitsLoaderQuery, AnonymousFlagsmithIdentityAndTraitsLoaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnonymousFlagsmithIdentityAndTraitsLoaderQuery, AnonymousFlagsmithIdentityAndTraitsLoaderQueryVariables>(AnonymousFlagsmithIdentityAndTraitsLoaderDocument, options);
      }
export function useAnonymousFlagsmithIdentityAndTraitsLoaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnonymousFlagsmithIdentityAndTraitsLoaderQuery, AnonymousFlagsmithIdentityAndTraitsLoaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnonymousFlagsmithIdentityAndTraitsLoaderQuery, AnonymousFlagsmithIdentityAndTraitsLoaderQueryVariables>(AnonymousFlagsmithIdentityAndTraitsLoaderDocument, options);
        }
export function useAnonymousFlagsmithIdentityAndTraitsLoaderSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AnonymousFlagsmithIdentityAndTraitsLoaderQuery, AnonymousFlagsmithIdentityAndTraitsLoaderQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AnonymousFlagsmithIdentityAndTraitsLoaderQuery, AnonymousFlagsmithIdentityAndTraitsLoaderQueryVariables>(AnonymousFlagsmithIdentityAndTraitsLoaderDocument, options);
        }
export type AnonymousFlagsmithIdentityAndTraitsLoaderQueryHookResult = ReturnType<typeof useAnonymousFlagsmithIdentityAndTraitsLoaderQuery>;
export type AnonymousFlagsmithIdentityAndTraitsLoaderLazyQueryHookResult = ReturnType<typeof useAnonymousFlagsmithIdentityAndTraitsLoaderLazyQuery>;
export type AnonymousFlagsmithIdentityAndTraitsLoaderSuspenseQueryHookResult = ReturnType<typeof useAnonymousFlagsmithIdentityAndTraitsLoaderSuspenseQuery>;
export type AnonymousFlagsmithIdentityAndTraitsLoaderQueryResult = Apollo.QueryResult<AnonymousFlagsmithIdentityAndTraitsLoaderQuery, AnonymousFlagsmithIdentityAndTraitsLoaderQueryVariables>;